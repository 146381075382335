import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Player from './Player';

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(2),
    fontWeight: 'bold',
  },
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  transcript: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

const ModelAnswer = ({t, questionAnswer, transcript, question}) => {
  const classes = useStyles();

  const getTranscript = () => {
    if (transcript && transcript !== 'null') return transcript;
    if (transcript && transcript === 'null')
      return 'There is no model answer for this practice';
    return null;
  };

  const getAcceptableAnswer = () => {
    if (
      questionAnswer &&
      questionAnswer['acceptable-answers'] &&
      questionAnswer['acceptable-answers'].data &&
      questionAnswer['acceptable-answers'].data[0] &&
      questionAnswer['acceptable-answers'].data[0].answer
    ) {
      return questionAnswer['acceptable-answers'].data[0].answer;
    }
    return null;
  };

  const getModelAnswer = () => {
    switch (question?.question_type) {
      case 'ReadAloud':
        return {
          transcript: null,
          uri: getAcceptableAnswer(),
          userAnswerUri: true,
        };
      case 'DescribeImage':
        return {
          transcript: getTranscript(),
          uri: getAcceptableAnswer(),
          userAnswerUri: true,
        };
      case 'RepeatSentence':
        return {
          transcript: getTranscript(),
          uri: null,
          userAnswerUri: false,
        };
      case 'RetellLecture':
        return {
          transcript: getTranscript(),
          uri: getAcceptableAnswer(),
          userAnswerUri: getAcceptableAnswer() ? true : false,
          is_audio_show: question?.is_audio_show === 1 ? true : false,
        };
      case 'AnswerShortQuestion':
        return {
          transcript: getTranscript(),
          uri: getAcceptableAnswer(),
          userAnswerUri: false,
        };
      case 'SummarizeWrittenText':
        return {
          transcript: null,
          text: getAcceptableAnswer(),
          userAnswerUri: false,
        };
      case 'WriteEssay':
        return {
          transcript: null,
          text: getAcceptableAnswer(),
          userAnswerUri: false,
        };
      case 'SummarizeSpokenText':
        return {
          transcript: null,
          text: getAcceptableAnswer(),
          userAnswerUri: false,
        };
      case 'WriteFromDictation':
        return {
          transcript: null,
          text: getAcceptableAnswer(),
          userAnswerUri: false,
        };

      default:
        return {
          text: null,
          uri: null,
          userAnswerUri: false,
        };
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.header}>
            {t('modelAnswer')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {(!getModelAnswer().userAnswerUri ||
            getModelAnswer()?.is_audio_show) && (
            <Typography className={classes.content}>
              {/* {getModelAnswer().transcript} */}
              <div
                dangerouslySetInnerHTML={{__html: getModelAnswer().transcript}}
              />
            </Typography>
          )}
          {getModelAnswer().text && (
            <Typography className={classes.content}>
              {/* {getModelAnswer().text} */}
              <div
                dangerouslySetInnerHTML={{__html: getModelAnswer().text}}
                onCopy={(e) => {
                  e.preventDefault();
                }}
              />
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          {getModelAnswer().userAnswerUri && getModelAnswer().uri && (
            <Grid item lg={6} md={6} sm={12}>
              <Player
                src={getModelAnswer().uri}
                t={t}
                isAnswer={false}
                onPlayStarted={() => {}}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
ModelAnswer.propTypes = {
  t: PropTypes.func.isRequired,
  questionAnswer: PropTypes.shape().isRequired,
  transcript: PropTypes.string,
  question: PropTypes.object.isRequired,
};
ModelAnswer.defaultProps = {
  transcript: null,
};
export default ModelAnswer;
