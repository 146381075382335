import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Slider from 'react-slick';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {useRouter} from 'next/router';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import {
  getStatisticAction,
  setShowupModalAction,
  fetchAllSessionsAction,
  getNotificationPostListAction,
  getBannerPostListAction,
} from 'core/lib/adapters/redux/actions';
import SearchIcon from '@material-ui/icons/Search';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StudyPlan from 'components/StudyPlan/index';
import NoStudyPlan from 'components/StudyPlan/noStudyPlan';
import VideoGuides from 'components/Dashbord/VideoGuides';
import AnalyticsInDashboard from 'components/Analytics/AnalyticsInDashboard';
import Modals from 'components/StudyPlan/Modals';
import SessionList from 'components/Session/SessionList';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
} from '@material-ui/core';
import gradeBImage from '../../../assets/images/engGradeB.png';
import dashboardImg from '../../../assets/icons/dashboardBanner.svg';
import ExamTarget from '../../Analytics/AnalyticsInDashboard/ExamTarget';
import DashMockTest from '../../Analytics/AnalyticsInDashboard/DashMockTest';
import StudyMaterials from '../../Analytics/AnalyticsInDashboard/StudyMaterials';
import BenchmarkPTELessons from '../../Analytics/AnalyticsInDashboard/BenchmarkPTELessons';
import RecentEvents from '../../Analytics/AnalyticsInDashboard/RecentEvents';
import AIAnalysisRobo from '../../../components/AIAnalysisRobo/index';
import {getCategoriesAction} from 'core/lib/adapters/redux/actions/pte-course';
import {
  getDashboardFiltersActor,
  getDashboardFreeVideoActor,
  getSearchQuestionAction,
  showProgress,
} from 'core/lib/adapters/redux/actions/dashboard';
import alarmExam from '../../../assets/icons/alarmExam.svg';
import Pagination from '@material-ui/lab/Pagination';
import {createStudyPlanAction} from 'core/lib/adapters/redux/actions/studyPlanActions';
import {getPackageIndexAction} from 'core/lib/adapters/redux/actions/package';
import Skeleton from '@material-ui/lab/Skeleton';
import Link from 'next/link';
import CommanDialog from '../../Common/CommanDialog';
import MockTestIcon from 'components/Icons/gen/MockTest';
import BookIcon from 'components/Icons/gen/Book';
import theme from '../../../utils/theme';
import analytics from '../../../assets/icons/analytics.svg';
// import { useRouter } from 'next/navigation';
const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
  },
  headerTitle: {
    fontWeight: 500,
    fontSize: 18,
    color: '#fff',
  },
  writeEnglishImage: {
    backgroundImage: `url(${gradeBImage})`,
    height: '100%',
  },
  writeEnglishContainer: {
    display: 'flex',
    gap: '12px',
    backgroundColor: '#ffffff',
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderRadius: 6,
  },
  marqueeText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 14,
  },
  wishUserNameBox: {
    display: 'flex',
    gap: '2px',
  },
  headerImageContainer: {
    marginBottom: theme.spacing(2),
  },

  Container: {
    maxHeight: 600,
    height: 600,
    overflowY: 'auto',
    marginBottom: theme.spacing(1),
  },
  boxForDisplay: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
  },
  studyPlanContainer: {
    backgroundColor: '#e5e5e5',
    width: '64%',
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'noWrap',
    alignItems: 'flex-start',
    // borderTop: '2px solid #0F4C8F',
  },
  ProgressContainer: {
    backgroundColor: '#e5e5e5',
    width: '48%',
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // borderTop: '2px solid #0F4C8F',
    background: '#fff',
  },
  studyPlanHeader: {
    backgroundColor: '#fff',
    padding: '35px 20px 11px 20px',
    maxHeight: 86,
  },
  studyPlanBody: {
    backgroundColor: '#fff',
    padding: '0px 20px 20px 20px ',
    // height:361,
    // maxHeight:361,
  },
  videoGuidesBody: {
    padding: '0px 20px 20px 20px ',
  },
  comparePackagesContainer: {
    backgroundColor: '#fff',
    // maxHeight: 625,
    minHeight: 625,
    padding: '13px 20px 20px 20px ',
  },
  analyticsContainer: {
    backgroundColor: '#fff',
    maxHeight: 625,
    minHeight: 625,
    padding: '0px 20px 20px 20px ',
  },

  VideosGuidesContainer: {
    backgroundColor: '#fff',
    width: '34%',
    marginBottom: theme.spacing(2),
    borderTop: '2px solid #0F4C8F',
  },

  sessionContainer: {
    backgroundColor: '#fff',
    padding: '35px 20px',
    marginBottom: theme.spacing(2),
    borderTop: '2px solid #0F4C8F',
    width: '48%',
  },

  gotoOtherPage: {
    color: '#808080',
    fontSize: 13,
    cursor: 'pointer',
    fontWeight: 600,
  },
  gotoOtherPageSession: {
    color: theme.palette.primary.main,
    fontSize: 13,
    cursor: 'pointer',
    fontWeight: 600,
  },

  recreateBtn: {
    textTransform: 'capitalize',
    border: '1px solid #0F4C8F',
    padding: '10px 20px',
    backgroundColor: '#fff',
    marginTop: 20,
    color: theme.palette.primary.main,
    fontWeight: 600,
    borderRadius: 3,
    marginBottom: 20,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  mainBoxTitle: {
    fontWeight: 600,
    fontSize: 18,
    color: '#000',
    [theme.breakpoints.down('lg')]: {
      fontSize: 17,
    },
  },
  imageText: {
    fontSize: 18,
    textAlign: 'right',
    color: '#fff',
    fontWeight: 500,
  },
  imageTextMobile: {
    fontSize: '0.5em',
    textAlign: 'right',
    color: '#fff',
    fontWeight: 600,
  },
  joinBtn: {
    textTransform: 'capitalize',
    border: '2px solid #fff',
    borderRadius: 3,
    color: '#fff',
    backgroundColor: 'transparent',
    padding: '4px 30px',
    marginTop: 20,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&$selected': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  joinBtnMobile: {
    textTransform: 'capitalize',
    border: '1px solid #fff',
    borderRadius: 3,
    color: '#fff',
    fontSize: '0.6em',
    backgroundColor: 'transparent',
    padding: '0px 0px',
    marginTop: '2%',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&$selected': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  /// ////////////////////////session design

  root: {
    width: '100%',
  },
  sessionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // marginBottom: 30,
    // padding: '0px 58px',
  },
  sessionHeaderMobile: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
  },
  sessionHeaderTitle: {
    paddingTop: 30,
  },
  sessionHeaderTitleMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 30,
    paddingLeft: '6%',
  },
  liveZoomTitle: {
    fontWeight: 600,
  },
  mockIcon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    background: '#EDF0F2',
    padding: theme.spacing(0.7),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
  },
  filterBtn: {
    width: '100%',
    height: 50,
    backgroundColor: '#ffffff',
    borderRadius: 10,
    color: '#7C7C7C',
    fontSize: 16,
    fontWeight: 600,
  },
  filterBtnMobile: {
    height: 'auto',
    marginTop: theme.spacing(2),
  },

  listBox: {
    position: 'relative',
  },
  listBoxUl: {
    position: 'absolute',
    top: 63,
    width: '93%',
    backgroundColor: '#F1F2F6',
    zIndex: 1,
    borderRadius: 3,
    maxHeight: 300,
    height: 300,
    overflow: 'auto',
  },
  listBoxLi: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  marqueeContainer: {
    overflow: 'hidden',
    height: '20px',
    width: '100%',
  },
  marqueeContent: {
    animation: '$scroll 5s linear infinite',
  },
  '@keyframes scroll': {
    from: {transform: 'translateY(0)'},
    to: {transform: 'translateY(-100%)'},
  },
  examDaysLeftTag: {
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    gap: '40px',
    borderRadius: '50px',
    padding: '4px',
    width: 'fit-content',
    '@media (max-width: 991px)': {
      gap: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      gap: '12px',
    },
  },
  examDayLeft: {
    background:
      'linear-gradient(90deg, rgba(15,76,143,1) 0%, rgba(27,117,217,1) 100%)',
    color: '#ffffff',
    fontWeight: '600',
    padding: '5px 15px',
    borderRadius: '50px',
    '@media (max-width: 425px)': {
      fontSize: '12px',
    },
  },
  examDateText: {
    fontWeight: 600,
    '@media (max-width: 425px)': {
      fontSize: '13px',
      lineHeight: 'normal',
    },
  },
  alarmIcon: {
    width: '24px',
    height: '24px',
    [theme.breakpoints.down('sm')]: {
      width: '20px',
      height: '20px',
    },
  },
  whitePepar: {
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    padding: '24px 20px',
    position: 'sticky',
    top: 0,
    [theme.breakpoints.down('lg')]: {
      padding: '12px 12px',
    },
  },
  searchBarRedirection: {
    backgroundColor: '#ffffff',
    padding: '6px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    borderRadius: '50px',
    marginBottom: '12px',
  },
  peparBox: {
    backgroundColor: '#ffffff',
    borderRadius: '12px',
  },
  filterBtnData: {
    backgroundColor: '#ffffff',
  },
  textField: {
    padding: '0px',
    backgroundColor: '#fff',
    borderBottom: '1px solid #eee',
    borderRadius: 0,
    boxShadow: 'none',
    width: '100%',
    borderColor: 'rgba(0, 0, 0, 0)',
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '0px',
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '0px',
    },
    '& .MuiInputBase-input': {
      fontSize: '28px',
      fontWeight: '600',
      [theme.breakpoints.down('md')]: {
        fontSize: '20px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#fff',
      },
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff',
      },
    },
  },
  filterDataShow: {
    display: 'grid',
    gridTemplateColumns: '10% 90%',
    gap: '12px',
    alignItems: 'center',
    marginTop: '12px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '15% 85%',
      alignItems: 'start',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '20% 80%',
    },
  },
  filterOptionTitle: {
    fontSize: '18px',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  filterOptionButton: {
    [theme.breakpoints.down('md')]: {
      padding: '4px 6px',
      fontSize: '12px',
    },
  },
  mainBanner: {
    '& .MuiBox-root': {
      position: 'relative',
    },
    '& .slick-dots': {
      bottom: '2px',
      left: '5px',
      textAlign: 'left',
    },
    '& .slick-dots li.slick-active button:before': {
      color: theme.palette.primary.main,
      opacity: '1',
      width: '15px',
      height: '3px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50px',
      content: ' ',
      fontSize: '0px',
      marginTop: '8px',
    },
    '& .slick-dots li button:before': {
      color: '#A9BED7',
      opacity: '0.3',
      width: '3px',
      height: '3px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50px',
      content: ' ',
      fontSize: '0px',
      marginTop: '8px',
      marginLeft: '4px',
      marginRight: '4px',
    },
    '& .slick-dots li': {
      width: '10px',
      height: '10px',
      margin: '0px',
    },
    '& .slick-dots li.slick-active': {
      width: '20px',
      height: '20px',
    },
    '& .slick-slider .slick-list': {
      borderRadius: '10px',
    },
  },
  BannerTextBlue: {
    fontSize: '20px',
    color: theme.palette.primary.main,
    fontWeight: '600',
    whiteSpace: 'nowrap',
  },
  BannerTextGreen: {
    fontSize: '20px',
    color: '#319753',
    fontWeight: '600',
    whiteSpace: 'nowrap',
  },

  innerContain: {
    backgroundImage: `url(${dashboardImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    // padding: '10px',
    // margin: '6px',
    borderRadius: '6px',
    // position: 'absolute !important',
    // top: '8px',
    // left: '12px',
    // width: '75%'
  },
  imgText: {
    textAlign: 'center',
  },
  bannerTitleText: {
    display: 'flex',
    gap: '24px',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '18px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: '10px',
    },
  },
  desireScore: {
    fontSize: '12px',
    letterSpacing: '2px',
    color: theme.palette.primary.main,
    textTransform: 'UpperCase',
    fontWeight: '600',
    textAlign: 'center',
    marginTop: '40px',
    marginBottom: '16px',
  },
  searchBoxMain: {
    padding: '8vh 16vh',
    borderBottom: '1px solid #ccc',
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      padding: '4vh 2vh',
    },
  },
  searchResId: {
    fontSize: '12px',
    color: '#808080',
    backgroundColor: '#eee',
    borderRadius: '5px',
    padding: '3px 8px',
  },
  searchTitle: {
    fontWeight: '600',
    color: theme.palette.primary.main,
  },
  searchQuestion: {
    fontSize: '14px',
  },
  searchBoxBoredr: {
    borderTop: '1px solid #eee',
    padding: '24px 0',
  },
  dividerMargin: {
    marginTop: '20px',
    marginBottom: '20px',
    '@media (max-width: 576px)': {
      marginTop: '10px',
      marginBottom: '10px',
    },
  },
  introduUserExamBox: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 767px)': {
      flexDirection: 'column-reverse',
    },
  },
}));

const months = [
  {order: 0, name: 'January'},
  {order: 1, name: 'February'},
  {order: 2, name: 'March'},
  {order: 3, name: 'April'},
  {order: 4, name: 'May'},
  {order: 5, name: 'June'},
  {order: 6, name: 'July'},
  {order: 7, name: 'August'},
  {order: 8, name: 'September'},
  {order: 9, name: 'October'},
  {order: 10, name: 'November'},
  {order: 11, name: 'December'},
];

const DashboardD = ({useragent}) => {
  const {t} = useTranslation();
  const router = useRouter();
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [month, setMonth] = useState({
    order: moment().get('month'),
    name: months.find((i) => i.order == moment().get('month')).name,
  });
  const [year, setYear] = useState(moment().year());
  const [years, setYears] = useState([]);
  const [monthListShow, setMonthListShow] = useState(false);
  const [yearListShow, setYearListShow] = useState(false);
  const [filterShow, setFilterShow] = useState(true);
  const [isMobile, setIsMobile] = useState(true);
  const [isMarquee, setMarquee] = useState(true);
  const [greeting, setGreeting] = useState('');
  const [aiAnalysis, setaiAnalysis] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const [bannerImg, setBannerImg] = useState('');
  const [bannerUrl, setBannerUrl] = useState('');
  const [open, setOpen] = useState(true);
  const [activeFilter, setActiveFilter] = useState({});
  const [searchVal, setSearchVal] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sliderPackageList, setSliderPackageList] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };
  const {studyPlan, notificationPost, bannerPost} = useSelector((state) => ({
    studyPlan: state.studyPlan.studyPlan,
    notificationPost: state.post.notificationPost,
    bannerPost: state.post.bannerPost,
  }));
  const user = useSelector((state) => state.auth.user);

  const isLogin = localStorage?.getItem('isLogin');
  const dashboardFilterData = useSelector(
    (state) => state?.dashboard?.dashboardFilterData?.data?.filters?.data || [],
  );
  const dashboardVideoData = useSelector(
    (state) => state?.dashboard?.dashboardFreeVideoData?.data?.data || [],
  );
  const searchData = useSelector(
    (state) => state?.dashboard?.dashboardSearchData?.data?.data || [],
  );
  const searchPagination = useSelector(
    (state) => state?.dashboard?.dashboardSearchData?.data?.meta || [],
  );
  const showProgressData = useSelector(
    (state) => state?.dashboard?.showProgress,
  );
  const packageList = useSelector((state) => state?.package?.packages?.data);

  const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');
  console.log(user, 'user (*/ω＼*)');
  useEffect(() => {
    if (!hasVisitedBefore) {
      setaiAnalysis(true);
      localStorage.setItem('hasVisitedBefore', 'true');
    }
  }, [hasVisitedBefore]);
  const handleChangePage = (event, page) => {
    setCurrentPage(page);
  };
  // useEffect(() => {}, [searchPagination]);

  const itemsPerPage = searchPagination?.pagination?.count;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = searchData?.slice(startIndex, endIndex);
  const redirectToNewPage = (e, href) => {
    e.preventDefault();
    if (href) {
      router.push(href);
    }
  };

  const renderYears = () => {
    const a = [];
    for (let i = moment().year(); i >= 2015; i--) {
      a.push(i);
    }
    setYears(a);
  };

  // const renderStudyPlan = () => {
  //   if (
  //     studyPlan &&
  //     Object.keys(studyPlan).length !== 0 &&
  //     studyPlan.constructor === Object
  //   )
  //     return <StudyPlan useragent={useragent} isInDashboard />;
  //   if (
  //     studyPlan &&
  //     Object.keys(studyPlan).length === 0 &&
  //     studyPlan.constructor === Object
  //   )
  //     return <NoStudyPlan />;
  //   return (
  //     <Grid container item alignItems="center" justify="center">
  //       <CircularProgress size={24} />
  //     </Grid>
  //   );
  // };

  const renderStudyPlanBtn = () => {
    if (
      studyPlan &&
      Object.keys(studyPlan).length !== 0 &&
      studyPlan.constructor === Object
    )
      return 'Recreate Study Plan';
    if (
      studyPlan &&
      Object.keys(studyPlan).length === 0 &&
      studyPlan.constructor === Object
    )
      return 'Create Study Plan';
    return (
      <Grid container item alignItems="center" justify="center">
        <CircularProgress size={24} />
      </Grid>
    );
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  const settingsVertical = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 1000,
    beforeChange: function (currentSlide, nextSlide) {},
    afterChange: function (currentSlide) {},
  };
  useEffect(() => {
    dispatch(
      fetchAllSessionsAction({
        page: 1,
        per_page: 15,
        search: `${year}-${month.order + 1}-${moment().day()}`,
      }),
    );
  }, [year, month]);

  useEffect(() => {
    if (bannerPost && bannerPost.data && bannerPost.data[0]) {
      const obj =
        bannerPost.data[0].text && JSON.parse(bannerPost.data[0].text);
      setBannerUrl(obj.url);
      setBannerImg(obj.img);
    }
  }, [bannerPost]);

  useEffect(() => {
    renderYears();
    dispatch(
      getStatisticAction({
        type: 'count',
        params: {
          period: 'yearly',
          blocks: 'practices-by-category,questions-by-category',
        },
      }),
    );
    dispatch(getNotificationPostListAction({category: 41}));
    dispatch(getBannerPostListAction({category: 42}));
    // dispatch(
    //   getPackageIndexAction({
    //     page: 1,
    //     per_page: 30,
    //   }),
    // );
  }, []);
  useLayoutEffect(() => {
    dispatch(
      getPackageIndexAction({
        page: 1,
        per_page: 30,
      }),
    );
  }, []);

  useEffect(() => {
    if (packageList?.length > 0) {
      const activePackages = packageList.filter((item) => item.is_base === 1);
      setSliderPackageList(activePackages);
    }
  }, [packageList]);
  useEffect(() => {
    const getCurrentTime = () => {
      const currentHour = new Date().getHours();

      if (currentHour >= 6 && currentHour < 12) {
        setGreeting('Good Morning');
      } else if (currentHour >= 12 && currentHour < 18) {
        setGreeting('Good Afternoon');
      } else {
        setGreeting('Good Evening');
      }
    };

    getCurrentTime();

    // Update the greeting every minute (60,000 milliseconds)
    const interval = setInterval(getCurrentTime, 60000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   dispatch(getDashboardSearchAction());
  // }, []);

  // const testfuncation = () => {
  //   let category = 10
  //   const search = "TEST"
  //   dispatch(getSearchQuestionAction({ category, search }));
  //   dispatch(getDashboardFiltersActor());
  //   dispatch(getDashboardFreeVideoActor());
  // }

  // useEffect(() => {
  //   testfuncation()
  // }, [])

  const handalSearch = () => {
    let category = activeFilter?.id ? activeFilter?.id : 0;
    const search = searchVal ? searchVal : 'TEST';
    dispatch(getSearchQuestionAction({category, search}));
  };
  useEffect(() => {
    let isProgress = false;
    dispatch(showProgress(isProgress));
    dispatch(getDashboardFiltersActor());
    dispatch(getDashboardFreeVideoActor());
  }, []);

  const handleSearchBarClick = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const setFilter = ({id, name}) => {
    setActiveFilter({
      id,
      name,
    });
  };

  useEffect(() => {
    if (Object.entries(dashboardFilterData).length > 0) {
      setActiveFilter({
        id: dashboardFilterData?.Listening?.[0]?.id || 0,
        name: dashboardFilterData?.Listening?.[0]?.name?.toLowerCase() || '',
      });
    }
  }, [dashboardFilterData]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     localStorage?.setItem("isLogin", true)
  //   }, 2000);
  // }, [])

  var date1 = moment(user?.date_of_exam);
  var date2 = moment();
  var days = date1.diff(date2, 'days');
  if (days < 0) {
    days = 0;
  }
  var formattedDate1 = date1.format('DD-MM-YYYY');

  // useEffect(() => {
  //   dispatch(
  //     createStudyPlanAction({
  //       user_id: user.id,
  //       type: 'plan',
  //     }),
  //   );
  // }, [user])

  return (
    <Grid container direction="column">
      {notificationPost &&
        notificationPost.data &&
        notificationPost.data[0] &&
        notificationPost.data[0].title !== 'off' &&
        isMarquee && (
          <Box className={classes.writeEnglishContainer}>
            <marquee attribute_name="attribute_value" attributes>
              <Box style={{display: 'flex', alignItems: 'center', gap: '50px'}}>
                <Typography className={classes.marqueeText}>
                  {' '}
                  {notificationPost.data[0].text}
                </Typography>
                <Typography className={classes.marqueeText}>
                  {' '}
                  {notificationPost.data[0].text}
                </Typography>
                <Typography className={classes.marqueeText}>
                  {' '}
                  {notificationPost.data[0].text}
                </Typography>
              </Box>
            </marquee>
            <CloseIcon
              style={{color: '#0F4C8F'}}
              onClick={() => {
                setMarquee(false);
              }}
            />
          </Box>
        )}
      {bannerImg && (
        <Grid container onClick={(e) => redirectToNewPage(e, bannerUrl)}>
          <Grid
            container
            className={classes.headerImageContainer}
            alignItems="center">
            <Grid
              item
              xs={12}
              container
              justify="center"
              style={{position: 'relative'}}
              alignItems="center">
              <img
                src={bannerImg}
                alt="Banner"
                style={{width: '100%', cursor: 'pointer'}}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12}>
          <Box className={classes.introduUserExamBox}>
            <Box style={{maxWidth: '400px'}}>
              <Slider {...settingsVertical}>
                <Box>
                  <Box className={classes.wishUserNameBox}>
                    <Typography variant="h6" style={{fontWeight: '400'}}>
                      {greeting},
                    </Typography>
                    <Typography variant="h6" style={{fontWeight: '600'}}>
                      {user?.name}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box className={classes.wishUserNameBox}>
                    <Typography variant="h6" style={{fontWeight: '400'}}>
                      {'Hola'},
                    </Typography>
                    <Typography variant="h6" style={{fontWeight: '600'}}>
                      {user?.name}
                    </Typography>
                  </Box>
                </Box>
              </Slider>
            </Box>
            <Box className={classes.examDaysLeftTag}>
              <Box
                style={{
                  display: 'flex',
                  gap: '6px',
                  marginLeft: '4px',
                  alignItems: 'center',
                }}>
                <img
                  src={alarmExam}
                  className={classes.alarmIcon}
                  alt="reminder"
                />
                <Typography
                  className={classes.examDateText}
                  variant="subtitle2">
                  {t('examDate')} :{' '}
                  {user?.date_of_exam ? formattedDate1 : 'Date not set'}
                </Typography>
              </Box>
              <Typography variant="subtitle2" className={classes.examDayLeft}>
                {days || '0'} {t('daysLeft')}
              </Typography>
            </Box>
          </Box>
          <Typography variant="subtitle1" style={{fontWeight: '600'}}>
            {'Welcome Back!'}
          </Typography>
          <Divider className={classes.dividerMargin} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Hidden lgUp>
          {!showProgressData ? (
            <DashboardDataMain
              classes={classes}
              handleSearchBarClick={handleSearchBarClick}
              isDrawerOpen={isDrawerOpen}
              handleCloseDrawer={handleCloseDrawer}
              searchVal={searchVal}
              paginatedData={paginatedData}
              settings={settings}
              renderStudyPlanBtn={renderStudyPlanBtn}
              dashboardVideoData={dashboardVideoData}
              setSearchVal={setSearchVal}
              handalSearch={handalSearch}
              searchData={searchData}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              handleChangePage={handleChangePage}
              dashboardFilterData={dashboardFilterData}
              activeFilter={activeFilter}
              setFilter={setFilter}
              sliderPackageList={sliderPackageList}
            />
          ) : (
            <DashboardProgress classes={classes} t={t} useragent={useragent} />
          )}
        </Hidden>
        <Hidden mdDown>
          <DashboardDataMain
            classes={classes}
            handleSearchBarClick={handleSearchBarClick}
            isDrawerOpen={isDrawerOpen}
            handleCloseDrawer={handleCloseDrawer}
            searchVal={searchVal}
            paginatedData={paginatedData}
            settings={settings}
            renderStudyPlanBtn={renderStudyPlanBtn}
            dashboardVideoData={dashboardVideoData}
            setSearchVal={setSearchVal}
            handalSearch={handalSearch}
            searchData={searchData}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
            dashboardFilterData={dashboardFilterData}
            activeFilter={activeFilter}
            setFilter={setFilter}
            sliderPackageList={sliderPackageList}
          />
          <DashboardProgress classes={classes} t={t} useragent={useragent} />
        </Hidden>
      </Grid>
    </Grid>
  );
};
const DashboardDataMain = ({
  classes,
  handleSearchBarClick,
  isDrawerOpen,
  handleCloseDrawer,
  searchVal,
  dashboardFilterData,
  paginatedData,
  settings,
  renderStudyPlanBtn,
  dashboardVideoData,
  setSearchVal,
  handalSearch,
  searchData,
  itemsPerPage,
  currentPage,
  handleChangePage,
  activeFilter,
  setFilter,
  sliderPackageList,
}) => {
  const [practiceModal, setPracticeModal] = useState(false);
  const [iconColor, setIconColor] = useState('#09121F');
  const router = useRouter();
  const ContinueResultData = [
    {title: 'Speaking', icon: <ArrowForwardIcon /> , link : '/practice/Speaking'},
    {title: 'Writing', icon: <ArrowForwardIcon /> , link : '/practice/Writing'},
    {title: 'Reading', icon: <ArrowForwardIcon /> , link : '/practice/Reading'},
    {title: 'Listening', icon: <ArrowForwardIcon /> , link : '/practice/Listening'},
  ];
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIconColor((prevColor) => {
        if (prevColor === '#09121F') {
          return '#E2B207';
        } else if (prevColor === '#E2B207') {
          return '#EC3C3C';
        } else {
          return '#09121F';
        }
      });
    }, 999);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <Grid item xl={9} lg={9} md={12} xs={12}>
        <Box
          className={classes.searchBarRedirection}
          onClick={handleSearchBarClick}>
          <SearchIcon style={{color: '#808080'}} />
          <Typography
            variant="subtitle2"
            style={{color: '#808080', fontWeight: '600'}}>
            Search Question Content
          </Typography>
        </Box>
        <Drawer anchor="top" open={isDrawerOpen} onClose={handleCloseDrawer}>
          <Box>
            <Box className={classes.searchBoxMain}>
              <IconButton
                aria-label="close"
                onClick={handleCloseDrawer}
                style={{float: 'right'}}>
                <CloseIcon />
              </IconButton>
              <Box>
                <TextField
                  placeholder="Type something..."
                  variant="outlined"
                  value={searchVal || ''}
                  className={classes.textField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{
                            color: '#ccc',
                            fontSize: '28px',
                            marginRight: '12px',
                          }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {searchVal && (
                          <Button
                            variant="contained"
                            style={{marginRight: '12px'}}
                            onClick={() => handalSearch()}>
                            Submit
                          </Button>
                        )}
                        {/* <CloseIcon onClick={() => setSearch("")} style={{ color: '#ccc' }} /> */}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setSearchVal(e.target.value)}
                  style={{fontSize: '28px'}}
                />
                <Box className={classes.filterDataShow}>
                  <Typography
                    className={classes.filterOptionTitle}
                    style={{fontWeight: '600'}}>
                    Listening
                  </Typography>
                  <Box>
                    {dashboardFilterData?.Listening?.map((item, i) => {
                      return (
                        <Button
                          className={classes.filterOptionButton}
                          style={{
                            background:
                              activeFilter?.id === item?.id
                                ? '#0F4C8F'
                                : 'white',
                            color:
                              activeFilter?.id === item?.id ? 'white' : 'black',
                          }}
                          onClick={() =>
                            setFilter({
                              id: item?.id,
                              name: item?.name?.toLowerCase(),
                            })
                          }>
                          {item?.name?.toLowerCase()}
                        </Button>
                      );
                    })}
                  </Box>
                </Box>
                <Box className={classes.filterDataShow}>
                  <Typography
                    className={classes.filterOptionTitle}
                    style={{fontWeight: '600'}}>
                    Speaking
                  </Typography>
                  <Box>
                    {dashboardFilterData?.Speaking?.map((item, i) => {
                      return (
                        <Button
                          className={classes.filterOptionButton}
                          style={{
                            background:
                              activeFilter?.id === item?.id
                                ? '#0F4C8F'
                                : 'white',
                            color:
                              activeFilter?.id === item?.id ? 'white' : 'black',
                          }}
                          onClick={() =>
                            setFilter({
                              id: item?.id,
                              name: item?.name?.toLowerCase(),
                            })
                          }>
                          {item?.name?.toLowerCase()}
                        </Button>
                      );
                    })}
                  </Box>
                </Box>
                <Box className={classes.filterDataShow}>
                  <Typography
                    className={classes.filterOptionTitle}
                    style={{fontWeight: '600'}}>
                    Reading
                  </Typography>
                  <Box>
                    {dashboardFilterData?.Reading?.map((item, i) => {
                      return (
                        <Button
                          className={classes.filterOptionButton}
                          style={{
                            background:
                              activeFilter?.id === item?.id
                                ? '#0F4C8F'
                                : 'white',
                            color:
                              activeFilter?.id === item?.id ? 'white' : 'black',
                          }}
                          onClick={() =>
                            setFilter({
                              id: item?.id,
                              name: item?.name?.toLowerCase(),
                            })
                          }>
                          {item?.name?.toLowerCase()}
                        </Button>
                      );
                    })}
                  </Box>
                </Box>
                <Box className={classes.filterDataShow}>
                  <Typography
                    className={classes.filterOptionTitle}
                    style={{fontWeight: '600'}}>
                    Writing
                  </Typography>
                  <Box>
                    {dashboardFilterData?.Writing?.map((item, i) => {
                      return (
                        <Button
                          className={classes.filterOptionButton}
                          style={{
                            background:
                              activeFilter?.id === item?.id
                                ? '#0F4C8F'
                                : 'white',
                            color:
                              activeFilter?.id === item?.id ? 'white' : 'black',
                          }}
                          onClick={() =>
                            setFilter({
                              id: item?.id,
                              name: item?.name?.toLowerCase(),
                            })
                          }>
                          {item?.name?.toLowerCase()}
                        </Button>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
              <Box style={{marginTop: '24px'}}>
                {paginatedData?.map((item, i) => {
                  return (
                    <>
                      <Box className={classes.searchBoxBoredr}>
                        <Box style={{display: 'flex', gap: '12px'}}>
                          <Typography className={classes.searchTitle}>
                            {item?.title}
                          </Typography>
                          <Typography className={classes.searchResId}>
                            {item?.id}
                          </Typography>
                        </Box>
                        <Typography className={classes.searchQuestion}>
                          {item?.question_data?.text}
                        </Typography>
                      </Box>
                    </>
                  );
                })}
                {paginatedData.length > 0 && (
                  <Pagination
                    shape="rounded"
                    count={Math.ceil(searchData.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handleChangePage}
                    // boundaryCount={3}
                    style={{
                      marginTop: '16px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    classes={{
                      root: classes.paginationRoot,
                      ul: classes.paginationUl,
                    }}
                  />
                )}
              </Box>
            </Box>
            <marquee attribute_name="attribute_value" attributes>
              <Box style={{display: 'flex', alignItems: 'center', gap: '50px'}}>
                <Typography
                  variant="subtitle1"
                  style={{color: '#0F4C8F', padding: '6px 0'}}>
                  Essentials Package costs only $5 for 2 weeks and include 10
                  mock tests. Don't miss it!
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{color: '#0F4C8F', padding: '6px 0'}}>
                  Essentials Package costs only $5 for 2 weeks and include 10
                  mock tests. Don't miss it!
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{color: '#0F4C8F', padding: '6px 0'}}>
                  Essentials Package costs only $5 for 2 weeks and include 10
                  mock tests. Don't miss it!
                </Typography>
              </Box>
            </marquee>
          </Box>
        </Drawer>
        <Box className={classes.mainBanner}>
          <Slider {...settings}>
            {sliderPackageList?.length > 0 ? (
              sliderPackageList.map((item) => (
                <Box key={item.id} className={classes.innerContain}>
                  <Box className={classes.imgText}>
                    <Box className={classes.bannerTitleText}>
                      <Typography className={classes.BannerTextBlue}>
                        {item.name}
                      </Typography>
                      <Hidden xsDown>
                        <Divider
                          orientation="vertical"
                          style={{height: '20px'}}
                        />
                      </Hidden>

                      {item.duration >= 30 ? (
                        <>
                          {Math.floor(item.duration / 30) > 1 ? (
                            <Typography className={classes.BannerTextGreen}>
                              Starts from ${item.price} for{' '}
                              {Math.floor(item.duration / 30)} Months
                            </Typography>
                          ) : (
                            <Typography className={classes.BannerTextGreen}>
                              Starts from ${item.price} per month
                            </Typography>
                          )}
                        </>
                      ) : (
                        <Typography className={classes.BannerTextGreen}>
                          Starts from ${item.price} for {item.duration} Days
                        </Typography>
                      )}
                    </Box>
                    <Typography className={classes.desireScore}>
                      Get Your Desire Score
                    </Typography>
                  </Box>
                </Box>
              ))
            ) : (
              <>
                <Skeleton variant="rectangular" width={'100%'} height={108} />
              </>
            )}

            {/* <Box className={classes.innerContain}>
              <Box className={classes.imgText}>
                <Box className={classes.bannerTitleText}>
                  <Typography className={classes.BannerTextBlue}>
                    1 Month Unlimited Class
                  </Typography>
                  <Hidden xsDown>
                    <Divider orientation="vertical" style={{height: '20px'}} />
                  </Hidden>
                  <Typography className={classes.BannerTextGreen}>
                    Starts from $99 per month
                  </Typography>
                </Box>
                <Typography className={classes.desireScore}>
                  Get Your Desire Score
                </Typography>
              </Box>
            </Box> */}
            {/* <Box className={classes.innerContain}>
              <Box className={classes.imgText}>
                <Box className={classes.bannerTitleText}>
                  <Typography className={classes.BannerTextBlue}>
                    1 Month Unlimited Class
                  </Typography>
                  <Hidden xsDown>
                    <Divider orientation="vertical" style={{height: '20px'}} />
                  </Hidden>
                  <Typography className={classes.BannerTextGreen}>
                    Starts from $99 per month
                  </Typography>
                </Box>
                <Typography className={classes.desireScore}>
                  Get Your Desire Score
                </Typography>
              </Box>
            </Box> */}
            {/* <Box className={classes.innerContain}>
              <Box className={classes.imgText}>
                <Box className={classes.bannerTitleText}>
                  <Typography className={classes.BannerTextBlue}>
                    1 Month Unlimited Class
                  </Typography>
                  <Hidden xsDown>
                    <Divider orientation="vertical" style={{height: '20px'}} />
                  </Hidden>
                  <Typography className={classes.BannerTextGreen}>
                    Starts from $99 per month
                  </Typography>
                </Box>
                <Typography className={classes.desireScore}>
                  Get Your Desire Score
                </Typography>
              </Box>
            </Box>
            <Box className={classes.innerContain}>
              <Box className={classes.imgText}>
                <Box className={classes.bannerTitleText}>
                  <Typography className={classes.BannerTextBlue}>
                    1 Month Unlimited Class
                  </Typography>
                  <Hidden xsDown>
                    <Divider orientation="vertical" style={{height: '20px'}} />
                  </Hidden>
                  <Typography className={classes.BannerTextGreen}>
                    Starts from $99 per month
                  </Typography>
                </Box>
                <Typography className={classes.desireScore}>
                  Get Your Desire Score
                </Typography>
              </Box>
            </Box> */}
          </Slider>
          <Hidden lgUp>
            <Box
              style={{
                backgroundColor: '#fff',
                padding: '6px',
                borderRadius: '50px',
                display: 'flex',
                gap: '12px',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '12px 0px',
              }}>
              <Box
                style={{
                  display: 'flex',
                  gap: '6px',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={()=>router.push('/mock')}>
                <MockTestIcon width={18} height={18} color={iconColor} />
                <Typography style={{color: iconColor}}>Mock Test</Typography>
              </Box>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Box
                style={{
                  display: 'flex',
                  gap: '6px',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => setPracticeModal(true)}>
                <BookIcon width={18} height={18} color={iconColor} />
                <Typography style={{color: iconColor}}>
                  Practice Tests
                </Typography>
              </Box>
            </Box>
          </Hidden>
          
        </Box>
        {/* <Grid
            item
            className={`${classes.studyPlanContainer} ${classes.Container}`}>
             <Grid
              item
              xs={12}
              className={classes.studyPlanHeader}
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start">
              <Typography variant="subtitle1" className={classes.mainBoxTitle}>
                Study Plan
              </Typography>
              <Grid
                xs={6}
                lg={4}
                 container
                item
                direction="row"
                justify="flex-end"
                alignItems="center">
                {studyPlan && (
                  <Grid
                    container
                    justify="flex-end"
                    onClick={(e) => redirectToNewPage(e, '/study-plan')}>
                    <Typography
                      variant="subtitle1"
                      className={classes.gotoOtherPage}>
                      View Full Plan
                    </Typography>
                    <ArrowForwardIcon style={{ color: '#0F4C8F' }} />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} container className={classes.studyPlanBody}>
              {renderStudyPlan()}
            </Grid>
            <Grid
              item
              xs={12}
              container
              alignItems="flex-start"
              justify="center"
              style={{ backgroundColor: '#fff' }}>
              <Button
                variant="contained"
                className={classes.recreateBtn}
                onClick={() => dispatch(setShowupModalAction({ statuse: true }))}
                elevation={0}>
                {renderStudyPlanBtn()}
              </Button>
            </Grid>
            <Modals />
          </Grid> */}
        {/* <img src={dashboardImg} alt="banner" width={'100%'} height={'auto'} /> */}
        <ExamTarget
          renderStudyPlanBtn={renderStudyPlanBtn}
          setShowupModalAction={setShowupModalAction}
        />

        <DashMockTest />
        <StudyMaterials />
        {/* <Box className={classes.peparBox} style={{ marginBottom: "24px" }}>
            <Box style={{ display: 'flex', alignItems: 'center', gap: '4px', justifyContent: 'space-between', padding: "8px 20px" }}>
              <Typography variant="h6" style={{ fontWeight: '600' }}>Online Lactures</Typography>
              <Box style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <Typography variant='subtitle2' > View All </Typography>
                <ArrowForwardIosIcon style={{ fontSize: '10px' }} />
              </Box>
            </Box>
            <Divider />
            <Box style={{ display: 'flex', gap: "30px", alignItems: 'center', justifyContent: 'end', marginTop: "12px" }}>
              {filterShow && (
                <Box style={{ display: 'flex', gap: "24px", }}>
                  <Box
                    className={classes.listBox}>
                    <Button
                      className={`${classes.filterBtnData} ${isMobile ? classes.filterBtnMobile : ''
                        }`}
                    
                      disableElevation
                      onClick={() => {
                        setMonthListShow(!monthListShow);
                      }}>
                      {month.name} <ExpandMoreIcon />
                    </Button>
                    {monthListShow && (
                      <List dense={false} className={classes.listBoxUl}>
                        {months.map((i) => (
                          <ListItem key={i.order}>
                            <ListItemText
                              onClick={() => {
                                setMonth(i);
                                setMonthListShow(false);
                              }}
                              className={classes.listBoxLi}
                              primary={`${i.name}`}
                            />
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </Box>
                  <Box
                    className={classes.listBox}>
                    <Button
                      className={`${classes.filterBtnData} ${isMobile ? classes.filterBtnMobile : ''
                        }`}
                    
                      disableElevation
                      onClick={() => {
                        setYearListShow(!yearListShow);
                      }}>
                      {year} <ExpandMoreIcon />
                    </Button>
                    {yearListShow && (
                      <List dense={false} className={classes.listBoxUl}>
                        {years.map((i) => (
                          <ListItem key={i}>
                            <ListItemText
                              onClick={() => {
                                setYear(i);
                                setYearListShow(false);
                              }}
                              className={classes.listBoxLi}
                              primary={`${i}`}
                            />
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
            <SessionList
              t={t}
              type="ss"
              Icon="Speaking"
              typeId="eee"
              useragent={useragent}
              hideFilterBtns={(value) => setFilterShow(value)}
              hideFilterBtnsInMobile={(value) => setIsMobile(value)}
              inDashboard
              year={year}
              month={month.order + 1}
            />
          </Box> */}
        <BenchmarkPTELessons dashboardVideoData={dashboardVideoData} />
        {/* <RecentEvents /> */}
      </Grid>

      <CommanDialog
        open={practiceModal}
        borderColor={'transparent'}
        title={'Select the category'}
        onClose={() => setPracticeModal(false)}
        body={
          <>
            <Divider style={{marginTop: '15px', marginBottom: '15px'}} />
            <Grid container spacing={1}>
              {ContinueResultData.map((item, index) => (
                <Grid item sm={6} xs={12} key={index} onClick={()=>router.push(item.link)}>
                  <Box
                    style={{
                      border: '1px solid #eee',
                      padding: '12px',
                      borderRadius: '8px',
                    }}>
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '4px',
                      }}>
                      <Typography>{item.title}</Typography>
                      <IconButton
                        color="primary"
                        aria-label="delete"
                        size="small">
                        {item.icon}
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </>
        }
        primaryButton={() => setPracticeModal(false)}
        primaryText={'Close'}
      />
    </>
  );
};
const DashboardProgress = ({classes, t, useragent}) => {
  const router = useRouter();

  return (
    <>
      <Grid item xl={3} lg={3} md={12} xs={12}>
        {/* <Grid className={classes.boxForDisplay}> */}
        <Box className={classes.whitePepar}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}>
            <Typography variant="subtitle1" className={classes.mainBoxTitle}>
              Your Progress
            </Typography>
            {/* <Grid
              xs={7}
              lg={6}
              container
              // className={classes.gotoOtherPage}
              item
              direction="row"
              justify="flex-end"
              onClick={(e) => redirectToNewPage(e, '/analytics')}
              alignItems="center"> */}
            <Box
              style={{display: 'flex', gap: '4px', alignItems: 'center'}}
              onClick={() => router.push('/analytics')}>
              {/* onClick={(e) => redirectToNewPage(e, '/analytics')}> */}
              <Typography variant="subtitle1" className={classes.gotoOtherPage}>
                View Analytics
              </Typography>
              <ArrowForwardIosIcon
                style={{color: '#808080', fontSize: '12px'}}
              />
            </Box>
          </Box>
          <AnalyticsInDashboard
            t={t}
            type="ss"
            typeId="eee"
            useragent={useragent}
          />
        </Box>
      </Grid>
    </>
  );
};
export default DashboardD;
