import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import CourseHoc from '../ModalComponents/CourseHoc';
import DocViewer from '../ModalComponents/DocViewer';
import { Button } from '@material-ui/core';

function ResourceModal(props) {
  const {t} = useTranslation();
 
  return (
    <CourseHoc
      t={t}
      title={props.title}
      showHint={false}
      showAppBarRight={false}
      showAppBarLeft={false}
      ResourceModalDownload={true}
      url = {props.content}
      >
      <DocViewer source={props.content} useragent={props.useragent} />
    </CourseHoc>
  );
}

ResourceModal.propTypes = {
  content: PropTypes.string.isRequired,
  title: PropTypes.string,
  useragent: PropTypes.object,
  url : PropTypes.string
  
};

ResourceModal.defaultProps = {
  title: '',
  useragent: {},
};

export default ResourceModal;
