import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {Hidden} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Listening from 'components/Icons/gen/Listening';
import Writing from 'components/Icons/gen/Writing';
import Speaking from 'components/Icons/gen/Speaking';
import Reading from 'components/Icons/gen/Reading';
import SvgDownload from '../../Icons/gen/Download';

export const downloadPDF = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = 'filename.pdf';
  link.click();
};

const useMobileStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  backButton: {
    paddingRight: 0,
  },
  backButtonArrow: {
    cursor: 'pointer',
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    textAlign: 'start',
    '& :after': {
      content: ' ',
      display: 'block',
      position: 'absolute',
      height: '1px',
      top: '9px',
      left: 0,
      right: 0,
      zIndex: -1,
    },
  },
  type: {
    fontSize: 14,
    fontWeight: 500,
  },

  mainTitelBox: {
    width: '70%',
  },
  rightTitelBox: {
    width: '15%',
  },
  leftTitelBox: {
    width: '15%',
  },
  downloadIcon : {
    cursor : 'pointer'
  }
}));


/* t, isAuth */
const MobileAppBar = ({
  title,
  rightTitle,
  Icon,
  stopTimer,
  resetTimer,
  showRight,
  showLeft,
  ResourceModalDownload = false,
  url
}) => {
  const classes = useMobileStyles();
  const theme = useTheme();
 
  const downloadPDF = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = 'filename.pdf';
    link.click();
  };
 
  return (
    <AppBar position="static" className={classes.appbar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Grid container alignItems="center" justify="space-between">
          {showLeft && (
            <Grid item xs={6} container direction="row" alignItems="center">
              <Grid item xs container alignItems="center" >
                <Avatar className={classes.practiceAvatar}>
                  <Icon color={theme.palette.primary.main} />
                </Avatar>
                <Typography
                  className={classes.type}
                  style={{fontWeight: 700}}
                  variant="h6"
                  display="inline">
                  {type}
                </Typography>
              </Grid>
            </Grid>
          )}
          {
            ResourceModalDownload && (
              <Grid item xs={1} container direction="row" alignItems="center">
              <Grid item xs container alignItems="center" style={{marginTop : '10px'}}>
                <Avatar className={classes.practiceAvatar}>
                  <SvgDownload color={theme.palette.primary.main} className={classes.downloadIcon} onClick={()=>{downloadPDF(url)}}/>
                </Avatar>
                
              </Grid>
            </Grid>
            )
          }

          {showRight && (
            <Grid
              item
              className={classes.rightTitelBox}
              // xs={typeof rightTitle === 'string' ? 2 : 4}
              xs={6}
              container
              justify="flex-start">
              <Typography variant="h6" className={classes.type}>
                {rightTitle &&
                  (typeof rightTitle === 'string' ? rightTitle : rightTitle())}
              </Typography>
            </Grid>
          )}
          {
            ResourceModalDownload && (
              <Grid
              item
              className={classes.rightTitelBox}
              // xs={typeof rightTitle === 'string' ? 2 : 4}
              container
              justify="flex-start">
              
            </Grid>
            )
          }

          <Grid
            container
            item
            className={classes.mainTitelBox}
            justify="center"
            alignItems="center"
             xs={12}
          >
            <Typography variant="h5" align="center" className={classes.title}>
              {title}
            </Typography>
          </Grid>

          {/* just for align title in web mobile view in the center */}
          <Grid
            item
            className={classes.leftTitelBox}
            //  xs={8}
          ></Grid>
          {/* just for align title in web mobile view in the center */}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

MobileAppBar.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  ResourceModalDownload : PropTypes.bool,
  url : PropTypes.string
};

const useDesktopStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  practiceAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    background: '#fff',
  },
  appbar: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
    height: '72px',
    display: 'flex',
    justifyContent: 'center',
  },
  toolbar: {
    minHeight: theme.spacing(7),
  },
  type: {
    marginLeft: theme.spacing(1),
    fontWeight: 600,
    fontSize: 20,
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
    textDecoration: 'underline',
  },
  downloadIcon : {
    cursor : 'pointer'
  }
}));

const DesktopAppBar = ({
  type,
  title,
  rightTitle,
  Icon,
  showRight,
  showLeft,
  ResourceModalDownload = false,
  url
}) => {
  const classes = useDesktopStyles();
  const theme = useTheme();

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appbar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <Grid container alignItems="center" justify="center">
            {showLeft && (
              <Grid item xs={3} container direction="row" alignItems="center">
                <Grid item xs container alignItems="center" wrap="nowrap">
                  <Avatar className={classes.practiceAvatar}>
                    <Icon color={theme.palette.primary.main} />
                  </Avatar>
                  
                </Grid>
              </Grid>
            )}
            {
              ResourceModalDownload && (
              <Grid item xs={3} container direction="row" alignItems="center">
                <Grid item xs container alignItems="center" wrap="nowrap">
                  <Avatar className={classes.practiceAvatar}>
                    <SvgDownload color={theme.palette.primary.main} className={classes.downloadIcon} onClick={()=>{downloadPDF(url)}}/>
                  </Avatar>
                 
                </Grid>
              </Grid>)
            }

            <Grid item xs={6}>
              <Typography variant="h5" align="center" className={classes.title}>
                {title}
              </Typography>
            </Grid>

            {showRight && (
              <Grid item xs={3} container justify="flex-end">
                <Typography variant="h6" className={classes.type}>
                  {typeof rightTitle === 'string' ? rightTitle : rightTitle()}
                </Typography>
              </Grid>
            )}
            {
              ResourceModalDownload && (
                <Grid item xs={3} container justify="flex-end">

              </Grid>
              )
            }

          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

DesktopAppBar.propTypes = {
  t: PropTypes.func.isRequired,
  Icon: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  showRight: PropTypes.bool,
  showLeft: PropTypes.bool,
  rightTitle: PropTypes.string,
  ResourceModalDownload : PropTypes.bool,
  url : PropTypes.string,
};

const CourseAppBar = ({
  t,
  type,
  title,
  rightTitle,
  loading,
  showLeft,
  showRight,
  ResourceModalDownload,
  url
}) => {
  const types = {
    Listening,
    Writing,
    Speaking,
    Reading,
  };

  return (
    <>
      <Hidden lgUp>
        <MobileAppBar
          t={t}
          type={type}
          title={title}
          rightTitle={rightTitle}
          showRight
          // showLeft={true}
          // stopTimer={stopTimer}
          // resetTimer={resetTimer}
          ResourceModalDownload={ResourceModalDownload}
          url = {url}

        />
      </Hidden>
      <Hidden mdDown>
        <DesktopAppBar
          t={t}
          type={type}
          title={title}
          rightTitle={rightTitle}
          Icon={types[type] || Reading}
          showRight={showRight}
          showLeft={showLeft}
          ResourceModalDownload={ResourceModalDownload}
          url = {url}
        />
      </Hidden>
    </>
  );
};

CourseAppBar.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['Reading', 'Listening', 'Writing', 'Speaking']),
  showRight: PropTypes.bool,
  showLeft: PropTypes.bool,
  rightTitle: PropTypes.string,
  ResourceModalDownload : PropTypes.bool,
  url : PropTypes.string
};

export default CourseAppBar;
