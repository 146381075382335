import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import PlayButton from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';
import VolumeIcon from './Icons/gen/Volume';
import VolumeX from './Icons/gen/VolumeX';
import DownloadIcon from './Icons/gen/Download';

import { Box, Button, useTheme } from '@material-ui/core';
import { cannotSkipModelAction } from 'core/lib/adapters/redux/actions/modelAction';
import { useDispatch, useSelector } from 'react-redux';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';



const useStyles = makeStyles((theme) => ({
    player: {
        // backgroundColor: '#E7EEF4',
        // height: theme.spacing(7),

        display: 'flex',
        alignItems: 'center',
        // height: '52px',
        justifyContent: 'center',


    },
    playerBoxUi: {
        width: '100%',
        maxWidth: '320px',
        marginBottom: '12px',
        // borderRadius: theme.spacing(0.5),
        // paddingRight: theme.spacing(2),
        // paddingLeft: theme.spacing(2),
        // paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(1),
        border: '2px solid',
        // borderColor:  theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
    },
    playerMobile: {
        backgroundColor: '#E7EEF4',
        // height: theme.spacing(7),
        width: '100%',
        borderRadius: theme.spacing(7),
        padding: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
        // height: '52px',
        justifyContent: 'center',
    },
    playButton: {
        // transform: 'rotate(-90deg)',\
        color: theme.palette.primary.main,
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),

        // marginRight: theme.spacing(2),
    },
    volumeButton: {
        // transform: 'rotate(-90deg)',\
        color: 'white',
        // marginLeft: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
    },
    timer: {
        width: 0,
        height: theme.spacing(0.6),
        background: theme.palette.primary.main,
        position: 'relative',
        borderRadius: '50px'
    },
    playIndicator: {
        position: 'absolute',
        right: '-5px',
        // top: `-${theme.spacing(0.5)}px`,
        top: `-3px`,
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        color: 'white',
        background: theme.palette.primary.main,
        padding: '0px'
    },
    timerWrapper: {
        // background: '#868686',
        background: '#ffffff',
        padding: '2px',
        borderRadius: '50px',
        width: '100%',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    timerLinerSlider: {
        backgroundColor: '#E7EEF4',
        // padding: '2px 12px',
        borderRadius: '50px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        height: '32px',
        [theme.breakpoints.down('sm')]: {
            // width: theme.spacing(3.5),
            height: '40px',
            padding: '2px 12px',
        },
    },
    title: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
        lineHeight: '50px'
    },
    root: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1.5),
        },
    },
    speedButton: {
        display: 'flex',
        alignItems: 'center',
        // width: '37px',
        // height: '30px',
        borderRadius: '30px',
        background: 'white',
        color: '#000',
        fontSize: '14px',
        fontFamily: 'Montserrat !important',
        padding: '2px 9px',
        fontWeight: 600,
        marginRight: '6px',
        '&:hover': {
            background: 'white',
            color: theme.palette.primary.main,
        },
        '& span': {
            fontSize: '14px',
            fontWeight: 600,
        },
    },
    speedPopover: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    speedPopoverItem: {
        fontSize: '14px',
        fontWeight: 600,
        color: theme.palette.primary.main,
    },
    volumePaper: {
        overflow: 'hidden',
    },
    volumePopover: {
        width: '250px',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        padding: '7px 10px 10px 10px',
        overflow: 'hidden',
    },
    iconPausePlay: {
        height: '35px',
        width: '35px',
        transition: '0.3s ease-in-out',
        // '&:hover': {
        //   backgroundColor: '#0A3564',
        //   opacity: 0.9
        // },
        [theme.breakpoints.down('sm')]: {
            height: '40px',
            width: '40px',
            marginRight: '8px',
        },
    },
    IconSpacing: {
        padding: '8px',
        [theme.breakpoints.down('sm')]: {
            padding: '4px',
        },
    },
    actionBtn: {
        display: 'flex',
        gap: '12px',
        alignItems: 'center',
        // marginTop: '24px',
        // marginBottom: '24px'
    },
    actionBtnStyle: {
        padding: '10px 18px'
    },
    playerStatus: {
        fontSize: '14px',
        color: '#999',
        fontWeight: 600
    }
}));

const Player = ({
    src,
    t,
    isAnswer,
    onPlayFinish,
    autoplayAfter,
    onPlayStart,
    pausePlay,
    height,
    useragent,
    mock,
    onPlayStarted,
    showStartTimer,
    playback,
    startRecord,
    setIsStopNextQuestion,
    setIsPlaying,
    setRecorded,
    setRecorderTimer,
    currentStatus,
    step,
    setRemainingTime,
    setisPlayerStatusRecord,
    justifyContentPlayer
}) => {
    // const playerId = `player${new Date().getTime()}`;
    // const timerId = `timer${new Date().getTime()}`;
    // const timerWrapperId = `timerWrapper${new Date().getTime()}`;

    const playerId = useRef(`player${Math.random().toString(36).substr(2, 9)}`).current;
    const timerId = `timer${playerId}`; // If needed, use the playerId for timerId as well
    const timerWrapperId = `timerWrapper${playerId}`;
    const speedMenu = 'speed_menu';
    const id = 'simple-popover';

    const classes = useStyles();
    const theme = useTheme()
    const dispatch = useDispatch()
    const [playing, setPlaying] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [volumeEl, setVolumeEl] = useState(null);
    const [volumeOpen, setVolumeOpen] = useState(false);
    const [speed, setSpeed] = useState(1);
    const isMenuOpen = Boolean(anchorEl);
    const [playAfter, setPlayAfter] = useState(null);
    const [volumeValue, setVolumeValue] = useState(100);
    const [dragging, setDragging] = useState(false);
    const [forceShowPlayButton, setForceShowPlayButton] = useState(false);

    const resumeModal = useSelector((state) => state?.model?.testResumed)
    const reportMistake = useSelector((state) => state?.model?.reportBug)

    const handleSpeedMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSpeedMenuClose = () => {
        setAnchorEl(null);
    };

    const handleVolumeMenuOpen = (event) => {
        setVolumeEl(event.currentTarget);
        setVolumeOpen(!volumeOpen);
    };

    const handleVolumeMenuClose = () => {
        setVolumeEl(null);
    };

    const changeVolume = (event, newValue) => {
        setVolumeValue(newValue);
    };

    const setSpeedHandler = (value) => {
        setSpeed(value);
        handleSpeedMenuClose();
        const media = document.getElementById(playerId);
        media.playbackRate = value;
    };

    const goToTime = (e) => {
        const timerWrapper = document.getElementById(timerWrapperId);
        const positionX =
            (e.pageX - timerWrapper.getBoundingClientRect().left) /
            timerWrapper.clientWidth;
        const media = document.getElementById(playerId);
        if (media.duration) media.currentTime = media.duration * positionX;
    };

    const onDragProgress = (e) => {
        const timerWrapper = document.getElementById(timerWrapperId);
        const positionX =
            (e.pageX - timerWrapper.getBoundingClientRect().left) /
            timerWrapper.clientWidth;
        const media = document.getElementById(playerId);
        if (media.duration && dragging)
            media.currentTime = media.duration * positionX;
    };

    const onTouchProgress = (e) => {
        const timerWrapper = document.getElementById(timerWrapperId);
        const positionX =
            (e.changedTouches[0].pageX - timerWrapper.getBoundingClientRect().left) /
            timerWrapper.clientWidth;
        const media = document.getElementById(playerId);
        if (media.duration) media.currentTime = media.duration * positionX;
    };

    const renderStartTimer = () => {
        const timerAbs = Math.abs(playAfter);
        const minute = Math.floor(timerAbs / 60);
        const second = timerAbs - 60 * Math.floor(timerAbs / 60);
        if (minute === 0) {
            return `${second > 9 ? second : `0${second}`}`;
        } else {
            return `${minute > 9 ? minute : `0${minute}`}:${second > 9 ? second : `0${second}`}`;
        }
        // return `${minute > 9 ? minute : `0${minute}`}:${second > 9 ? second : `0${second}`
        //   }`;
    };

    const onClickPlayButton = () => {
        setForceShowPlayButton(false);
        setPlayAfter(0);
        const media = document.getElementById(playerId);
        if (media.paused) {
            media.play()
                .then(() => {
                    setPlaying(true);
                    onPlayStarted();
                })
                .catch((e) => {
                    // fix firefox can't play recorded audio for the first time
                    if (!src.startsWith('http') && e.name === 'NotSupportedError') {
                        media.setAttribute('src', src);
                    }

                    console.error('Player error: ', e);
                });
        } else {
            setPlaying(false);
            media.pause();
        }
    };

    useEffect(() => {
        const media = document.getElementById(playerId);
        media.volume = volumeValue / 100;
    }, [volumeValue]);

    useEffect(() => {
        const media = document.getElementById(playerId);
        const timer = document.getElementById(timerId);
        media.addEventListener('ended', () => {
            setPlaying(false);
            onPlayFinish();
        });

        media.addEventListener('timeupdate', () => {
            onPlayStart();
            const barLength = 100 * (media.currentTime / media.duration)

            timer.style.width = `${barLength}%`;
        });
    }, [src]);

    useEffect(() => {
        if (!isAnswer && autoplayAfter && playAfter !== 0 && !reportMistake) {
            const timerTimeout = setTimeout(() => {
                setPlayAfter((prev) => prev - 1);
            }, 1000);
            return () => {
                clearTimeout(timerTimeout);
            };
        }
        if (playAfter === 0 && !pausePlay && resumeModal !== true && !reportMistake) {
            dispatch(cannotSkipModelAction(false))
            const media = document.getElementById(playerId);
            media
                .play()
                .then(() => {
                    setPlaying(true);
                })
                .catch((err) => {
                    setForceShowPlayButton(true);
                });
        }
    }, [playAfter, resumeModal, reportMistake]);

    useEffect(() => {
        if (reportMistake) {
            const media = document.getElementById(playerId);
            media.pause();
            setPlaying(false);
        } else {
            if (playing) {
                const media = document.getElementById(playerId);
                media.play();
                setPlaying(true);

            }
        }
    }, [reportMistake])
    useEffect(() => {
        if (setisPlayerStatusRecord) {
            if (playing) {
                setisPlayerStatusRecord(true)
            }
            else {
                setisPlayerStatusRecord(false)
            }
        }
    }, [playing])
    // useEffect(() => {
    //   console.log("useEffect called when puased 🎂🎂🎂", "pause play" , pausePlay ,"playing", playing)
    //   if (pausePlay && playing) {
    //     console.log("under this condtion pause play 🎂" , pausePlay , playing)
    //     // const media = document.getElementById(playerId);
    //     // media.pause();
    //     // setPlaying(false);
    //   }
    // }, [pausePlay, playing]);

    useEffect(() => {
        if (pausePlay) {
            const media = document.getElementById(playerId);
            media.pause();
            setPlaying(false);
        } else {
            if (playing) {
                const media = document.getElementById(playerId);
                media.play();
                setPlaying(true);
            }
        }
    }, [pausePlay])

    useEffect(() => {
        if (autoplayAfter) {
            setPlayAfter(autoplayAfter);
        }
        return () => setPlayAfter(null); // Cleanup function
    }, [autoplayAfter]);

    useEffect(() => {
        if (setIsPlaying) {
            if (playing) {
                setIsPlaying(true)
            }
            else {
                setIsPlaying(false)
            }
        }
    }, [playing])

    useEffect(() => {
        if (setIsStopNextQuestion) {
            if (playAfter <= 0 && !playing) {
                setIsStopNextQuestion(true)
            } else {
                setIsStopNextQuestion(false)
            }
        }
    }, [playing, playAfter])
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const audioref = useRef()
    useEffect(() => {
        if (audioref.current) {
            const audio = audioref.current;

            const updateTime = () => {
                setCurrentTime(audio.currentTime);
            };

            const setAudioDuration = () => {
                setDuration(audio.duration);
            };

            audio.addEventListener('timeupdate', updateTime);
            audio.addEventListener('loadedmetadata', setAudioDuration);

            return () => {
                audio.removeEventListener('timeupdate', updateTime);
                audio.removeEventListener('loadedmetadata', setAudioDuration);
            };
        }
    }, []);

    const formatTime = (time) => {
        if (!time || isNaN(time)) return "0:00";
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };

    const handleDownload = (audioUrl) => {
        const link = document.createElement('a');
        link.href = audioUrl;
        link.download = audioUrl.split('/').pop().split('?')[0];
        link.target = '_blank';
        link.click();
    }

    return (
        <Grid container className={classes.root} style={{ justifyContent: justifyContentPlayer || 'center', marginTop: step === 3 ? '0px' : theme.spacing(3), }} item xs={12}>

            {isAnswer && (step !== 3 && step !== 6) && <Typography className={classes.title} variant="body1">{t('yourAnswer')}</Typography>}


            <div className={step === 2 ? '' : classes.playerBoxUi} style={{ padding: step === 3 || step === 6 ? '0px' : '8px 16px', width: '100%', height: `${height}px`, borderColor: step == 3 || step == 6 ? 'transparent' : theme.palette.primary.main }}>
                {currentStatus ?
                    <>
                        <Typography style={{ fontWeight: 500, marginBottom: '6px' }}>Question Audio:</Typography>{showStartTimer && autoplayAfter && playAfter > 0 && !playing ?
                            <Typography className={classes.playerStatus}>Starting in {renderStartTimer()} seconds</Typography> :
                            <Typography className={classes.playerStatus}>{playing ? "Playing" : "Complete"}</Typography>}
                    </>
                    : null}

                <Box className={useragent.isDesktop ? classes.player : classes.playerMobile}>
                <span style={{ fontSize: '14px',
    color: '#999',
    fontWeight: 600}}>{formatTime(currentTime)}</span>
                    {!mock || forceShowPlayButton ? (
                        <IconButton className={classes.iconPausePlay} onClick={onClickPlayButton}>
                            {playing ? (<PauseIcon className={classes.playButton} />) : (<PlayButton className={classes.playButton} />)}</IconButton>) : (<IconButton disabled onClick={onClickPlayButton} color="primary" style={{ padding: '0px' }} />
                    )}
                    <Box className={classes.timerLinerSlider} >
                        <div
                            style={mock ? { cursor: 'not-allowed' } : null}
                            className={classes.timerWrapper}
                            id={timerWrapperId}
                            onClick={!mock && goToTime}
                            onMouseMove={!mock && onDragProgress}
                            onTouchMove={!mock && onTouchProgress}>
                            <div id={timerId} className={classes.timer}>
                                <span onDrag={() => { setDragging(true); }} className={classes.playIndicator} />
                            </div>
                        </div>

                        <IconButton onClick={handleVolumeMenuOpen} style={{ padding: '8px' }}>
                            {volumeValue === 0 ? <VolumeX className={classes.volumeButton} color="#ff0000" /> : <VolumeIcon className={classes.volumeButton} color="#000" />}
                            <Popover id={id} open={volumeOpen} anchorEl={volumeEl} onClose={handleVolumeMenuClose}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                                transformOrigin={{ vertical: 'top', horizontal: 'center', }}
                                classes={{ paper: classes.volumePaper, }}>
                                <Grid container spacing={2} className={classes.volumePopover}>
                                    <Grid item><VolumeDown /></Grid>
                                    <Grid item xs>
                                        <Slider value={volumeValue} aria-labelledby="continuous-slider" onChange={changeVolume} />
                                    </Grid>
                                    <Grid item><VolumeUp /></Grid>
                                </Grid>
                            </Popover>
                        </IconButton>

                        {!isAnswer && !mock && <IconButton aria-controls={speedMenu} aria-haspopup="true" className={classes.speedButton} onClick={handleSpeedMenuOpen}>{speed === 1 ? `x1.0` : `x${speed}`}</IconButton>}

                        {!mock ? (
                            <div>
                                <Menu id={speedMenu} className={classes.speedPopover} anchorEl={anchorEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'center' }} open={isMenuOpen} onClose={handleSpeedMenuClose}>
                                    <MenuItem className={classes.speedPopoverItem} onClick={() => setSpeedHandler(0.8)}>x0.8</MenuItem>
                                    <MenuItem className={classes.speedPopoverItem} onClick={() => setSpeedHandler(1)}>x1.0</MenuItem>
                                    <MenuItem className={classes.speedPopoverItem} onClick={() => setSpeedHandler(1.1)}>x1.1</MenuItem>
                                    <MenuItem className={classes.speedPopoverItem} onClick={() => setSpeedHandler(1.2)}>x1.2</MenuItem>
                                </Menu>
                            </div>
                        ) : null}

                        <audio id={playerId} ref={audioref} src={src}><source src={src} /></audio>
                        <span style={{ fontSize: '14px',
    color: '#999',
    fontWeight: 600}}>{formatTime(duration)}</span>
                    </Box>
                </Box>
            </div>
                    <CloudDownloadIcon onClick={()=>handleDownload(src)} style={{ cursor: 'pointer', fontSize: 40 , marginLeft : '10px' , marginTop : '7px' , color : theme.palette.primary.main}}/> 

            {playback &&
                <>
                    {!mock || forceShowPlayButton ?
                        <>
                            <Box className={classes.actionBtn}>
                                <Button className={classes.actionBtnStyle} disabled={playing} variant="contained" onClick={() => { setRecorded(false); startRecord(); setRecorderTimer(1); setRemainingTime(35) }}>Record</Button>
                                <Button className={classes.actionBtnStyle} disabled={playing} variant="contained" onClick={onClickPlayButton}>Playback</Button>
                                <Button className={classes.actionBtnStyle} disabled={!playing} variant="contained" onClick={onClickPlayButton}>Stop</Button>
                            </Box>
                        </>
                        :
                        <IconButton disabled onClick={onClickPlayButton} color="primary" />
                    }
                </>
            }
        </Grid>
    );
};

Player.propTypes = {
    src: PropTypes.string,
    t: PropTypes.func.isRequired,
    isAnswer: PropTypes.bool,
    pausePlay: PropTypes.bool,
    onPlayFinish: PropTypes.func,
    onPlayStart: PropTypes.func,
    autoplayAfter: PropTypes.number,
    height: PropTypes.number,
    useragent: PropTypes.object,
    mock: PropTypes.bool,
    showDownload: PropTypes.bool,
    onPlayStarted: PropTypes.func,
    showStartTimer: PropTypes.bool,
};

Player.defaultProps = {
    src: null,
    isAnswer: true,
    onPlayFinish: () => { },
    autoplayAfter: null,
    onPlayStart: () => { },
    pausePlay: false,
    height: null,
    useragent: {},
    mock: false,
    showDownload: false,
    onPlayStarted: () => { },
    showStartTimer: false,
};
export default Player;
