import React from 'react';
import { Dialog, Box, Typography, Button, DialogContent, Slide ,CircularProgress} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../utils/theme';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const TransitionUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyle = makeStyles((theme) => ({
    dialogBox: {
        padding: theme.spacing(3),
        borderRadius: '10px',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1.2),
        }
    },
    dialogTitle: {
        fontSize: '20px',
        fontWeight: 500,
        marginTop: '6px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '16px'
        }
    },
    dialogSubtitle: {
        fontSize: '16px',
        color: '#808080',
        [theme.breakpoints.down('xs')]: {
            fontSize: '14px'
        }
    },
    // dialog: {
    //     '&.MuiDialog-paperScrollPaper': {
    //         alignItems: 'start',
    //     },
    //     top: '50px', // Adjust this value to set the distance from the top
    //     [theme.breakpoints.down('sm')]: {
    //         top: '0',
    //     },
    // },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
    },
    buttonsGrid: {
        marginTop: '20px',
        display: 'flex',
        gap: '12px',
        [theme.breakpoints.down('xs')]: {
            gap: '6px'
        }
    },
    dialogBox2: {
        '& .MuiPaper-root': {
            backgroundColor: 'transparent',
            boxShadow: 'none'
        },
        '& .MuiDialog-scrollPaper': {
            // alignItems: 'start',
            position: 'relative',
        },
        '& .MuiDialog-paper': {
            '&::before': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: '50%',
                width: '100px',
                height: '5px',
                backgroundColor: '#fff',
                borderRadius: '50px',
                transform: 'translate(-50%, -50%)'
            },
        },

    }
}));
const CommanDialog = ({showPrimaryButtonFirst, transitionDerectionUp, maxWidth, buttonWidth, body, icon, title, subtext, subtext2, borderColor, open, onClose, SecondaryButton, primaryButton, SecondaryText, dialogColor, primaryText , loading = false}) => {
    const classes = useStyle();

    return (
        <Dialog className={transitionDerectionUp ? classes.dialogBox2 : classes.dialogBox2} open={open} onClose={onClose} maxWidth={maxWidth || "xs"} TransitionComponent={transitionDerectionUp ? TransitionUp : Transition} >
            <Box className={classes.dialogBox} style={{ backgroundColor: '#fff', textAlign: 'center', borderBottom: '4px solid', borderColor: borderColor, marginBottom: '15px' || '15px' }} >
                {icon}
                <Typography className={classes.dialogTitle}>{title}</Typography>
                <Typography className={classes.dialogSubtitle}>{subtext}</Typography>
                <Typography className={classes.dialogSubtitle}>{subtext2}</Typography>
                {body}
                <Box className={classes.buttonsGrid} style={{ alignItems: 'center', flexDirection: showPrimaryButtonFirst ? 'row-reverse' : 'row', justifyContent: buttonWidth ? 'center' : 'space-between' }}>
                    {SecondaryText && <Button onClick={SecondaryButton} variant="contained" style={{ whiteSpace: 'nowrap', backgroundColor: '#E7EEF4', color: '#000', width: '100%', maxWidth: buttonWidth || "100%", }}>
                        {SecondaryText}
                    </Button>}
                    <Button 
                        onClick={primaryButton} 
                        disabled={loading} 
                        variant="contained" 
                        style={{ whiteSpace: 'nowrap', backgroundColor: dialogColor || theme.palette.primary.main, width: '100%', maxWidth: buttonWidth || "100%" }} 
                    >
                        {loading ? <CircularProgress size={24} color="#FFFFFF" /> : primaryText}
                    </Button>
                </Box>
            </Box>
        </Dialog >
    );
};


export default CommanDialog;

